export const translations = {
    en: {
        title: "Age Verification",
        content: "The site you are about to visit contains content intended only for adults. Do you confirm that you are at least 18 years old?",
        confirmButton: "I confirm that I am 18 years old",
        backButton: "Back"
    },
    pl: {
        title: "Weryfikacja Wiekowa",
        content: "Strona, którą zamierzasz odwiedzić, zawiera treści przeznaczone wyłącznie dla osób pełnoletnich. Czy potwierdzasz, że masz co najmniej 18 lat?",
        confirmButton: "Potwierdzam, że mam 18 lat",
        backButton: "Wróć"
    }
};
