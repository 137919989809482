
import React from 'react';
import styled from 'styled-components';
import {Card, Row} from 'react-bootstrap';
import {InfluencerDataBase} from "../abstract/InfluencerDataBase";
import {getResourceUrl} from "../utils/urlUtils";
import ShareIconButton from "./ShareIconButton";

const Avatar = styled.img`
    max-width: 230px;
    height: auto;
    width: auto;
    border-radius: 50%;
    aspect-ratio: 1/1;
    object-fit: cover;
    padding: 0;
    border: 3px white solid;
    box-shadow: rgba(0, 0, 0, 0.05) 0 0 5px 0;
`;

const StyledCard = styled(Card)`
    padding: 20px 20px;
    margin: 0;
    text-align: center;
    text-decoration: none !important;
    border-radius: 1.25rem;
    background-color: rgba(255, 255, 255, 0.85);
`

interface Props {
    influencer: InfluencerDataBase;
}

const InfluencerPartProfile: React.FC<Props> = ({ influencer }) => {
    return (
        <StyledCard>
            <Row className={"justify-content-center"}>
            {influencer.src && <Avatar src={getResourceUrl(influencer.src)} alt={influencer.name} />}
    </Row>
    <h4 className="mt-4">{influencer.name}</h4>
        <p className="text-white-shadow mb-1">{influencer.description}</p>
            <Row className={"justify-content-center my-2"}>
    {influencer.socialMediaLinks?.map((link, index) => (
            <ShareIconButton key={link.id + index} link={link.link} restricted={link.restricted} icon={link.icon} />
    ))}
            </Row>
    </StyledCard>
);
};

    export default InfluencerPartProfile;
