import React from 'react';
import {icons} from "./icons";

interface IconProps {
    name: string;
}

const CustomIcon: React.FC<IconProps> = ({ name }) => {
    const Icon = icons[name];
    return Icon ? <>{Icon}</> : null;
};

export default CustomIcon;
