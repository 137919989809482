import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation, faXmark} from "@fortawesome/free-solid-svg-icons";
import {translations} from "../translations/AgeVerificationDialog.translations";

interface AgeVerificationDialogProps {
    onClose: () => void;
    onConfirm: () => void;
}

const AgeVerificationDialog: React.FC<AgeVerificationDialogProps> = ({ onClose, onConfirm }) => {
    const language = navigator.language.startsWith('pl') ? 'pl' : 'en';
    const { title, content, confirmButton, backButton } = translations[language];

    return (
        <div className="dialog-overlay">
            <div className="dialog-content">
                <div className="warning-icon"><FontAwesomeIcon className="fa-2x" icon={faTriangleExclamation}/></div>
                <h2>{title}</h2>
                <p>{content}</p>
                <div className="dialog-navigation">
                    <button className="warning-button" onClick={onConfirm}>{confirmButton}</button>
                    <button onClick={onClose}>{backButton}</button>
                </div>
                <button onClick={onClose} aria-label="close" className="x"><FontAwesomeIcon icon={faXmark}/></button>
            </div>
        </div>
    );
};


export default AgeVerificationDialog;
