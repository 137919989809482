import {css} from 'styled-components';

export const getIconColor = (iconName?: string) => {
    switch (iconName?.toLowerCase()) {
        case 'instagram':
            return css`
              color: #E1306C;
            `;
        case 'facebook':
            return css`
              color: #1877f2;
            `;
        case 'youtube':
            return css`
              color: #FF0000;
            `;
        case 'linkedin':
            return css`
              color: #0e76a8;
            `;
        case 'onlyfans':
            return css`
              color: #00AFF0;
              fill: #00AFF0;
            `;
        case 'twitch':
            return css`
              color: #6441a5;
            `;
        default:
            return css`
              color: black;
            `;
    }
};
