import React, {useCallback, useEffect, useState} from 'react';
import {InfluencerData} from "./abstract/InfluencerData";
import InfluencerProfile from "./components/InfluencerProfile";
import BackgroundComponent from "./components/BackgroundComponent";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import Footer from "./components/Footer";
import ShareApiWebButton from "./components/ShareApiWebButton";
import styled, {css, keyframes} from "styled-components";
import {getResourceUrl} from "./utils/urlUtils";
import {BrowserRouter, Navigate, Route, Router, Routes} from "react-router-dom";

const breatheAnimation = keyframes`
    0% { background-position: center 0; }
    50% { background-position: center 30px; }
    100% { background-position: center 0; }
`;
const ParallaxLayer = styled.div<{ $level: number, $offsetY: number }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    will-change: transform;
    z-index: -2;
    animation: ${breatheAnimation} ${({ $level }) => 6 + $level * 0.5}s ease-in-out infinite;

    ${({ $level }) => $level === 4 && css`
    @media (min-width: 992px) {
      width: 50%;
      left: 0;
    }
  `}

    ${({ $level }) => ($level === 3 || $level === 1) && css`
    @media (min-width: 992px) {
      width: 50%;
      left: 50%;
    }
  `}


    ${({ $level }) => $level === 2 && css`
        @media (min-width: 992px) {
            background-size: auto;

        }
  `}
`;

const App: React.FC = () => {
    const [influencer, setInfluencer] = useState<InfluencerData>();

    const metaTags = [
        {property: 'og:title', content: "Teenz"}
    ];

    const loadData = useCallback((url: string) => {
        fetch(url)
            .then((response) => response.json())
            .then((data: InfluencerData) => {
                if (!data) throw new Error("There is no data for given URL");
                setInfluencer(data);
            })
            .catch((error) => {
                console.error("Error while loading data:", error);
            });
    }, []);

    useEffect(() => {
        loadData(getResourceUrl('data/teenz.json'));
    }, [loadData]);

    const updatePageTitleAndMetaTags = (influencer: InfluencerData) => {
        document.title = influencer.name;

        const metaTags = [
            {property: 'og:title', content: "TeenZ"},
            {property: 'og:description', content: "TeenZ"},
            {property: 'og:type', content: 'profile'},
        ];

        return (
                <Helmet>
                    <title>TeenZ</title>
                    <meta name="description" content="TeenZ"/>
                    {metaTags.map(tag => (
                        <meta key={tag.property} property={tag.property} content={tag.content}/>
                    ))}
                </Helmet>
        );
    };

    return (
        <BrowserRouter >
        <Routes>
            <Route path="/"  element={
        <div className="main">
            {influencer &&
                <>
                    <header>
                        <ShareApiWebButton
                        title={"TeenZ"}
                        url={window.location.href}
                        text={"TeenZ"}
                    /></header>
                    <div className="container">
                        <section>
                            <InfluencerProfile influencer={influencer}/>
                           {/* {[1, 2, 3, 4].map((level) => (
                                <ParallaxLayer
                                    key={level}
                                    $level={level}
                                    $offsetY={level}
                                    style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/parallax/0${level}.png)`,
                                    }}
                                />
                            ))}*/}
                        </section>

                    </div>

                    <BackgroundComponent influencer={influencer} />
                </>

            }
            <Footer/>
        </div>}>
            </Route>
            <Route path="*" element={
                <Navigate to="/" />}>
            </Route>
        </Routes>
        </BrowserRouter>
    );
};

export default App;
