import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faTwitch,
    faTiktok,
    faYoutube,
    faInstagram,
    IconDefinition, faXTwitter, faLinkedin, faFacebook, faSpotify
} from '@fortawesome/free-brands-svg-icons';
import {faBan, faGlobe, faLock, faStore} from "@fortawesome/free-solid-svg-icons";
import CustomIcon from "../utils/CustomIcon";
import {getIconColor} from "../utils/IconColors";
import AgeVerificationDialog from "./AgeVerificationDialog";
import getIcon from "../utils/getIcon.jsx";

interface ButtonProps {
    $customStyles?: ReturnType<typeof css>;
}

const Button = styled.a<ButtonProps>`
  padding: 0;
  margin: 10px;
  text-align: center;
  text-decoration: none !important;
  border-radius: 50%;
    width: min-content;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.5rem;
  .btn-icon {
      padding: 0;
      margin: auto;
      position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      svg {
          
      ${(props) => props.$customStyles}
    }
  }
`;

interface Props {
    link?: string;
    icon?: string;
    restricted?: boolean;
    customStyles?: string;
}

const ShareButton: React.FC<Props> = ({link, icon, restricted}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleButtonClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (!link) {
            return;
        }

        if (restricted) {
            e.preventDefault();
            setIsDialogOpen(true);
            return;
        }

        window.open(link, '_blank', 'noopener,noreferrer');

    }

    const handleDialogConfirm = () => {
        setIsDialogOpen(false);
        window.open(link, '_blank', 'noopener,noreferrer');
    };

    const selectedIcon = icon && getIcon(icon);
    const customStyles = getIconColor(icon);

    return (
        <>
            {isDialogOpen &&
                <AgeVerificationDialog
                    onClose={() => setIsDialogOpen(false)}
                    onConfirm={handleDialogConfirm}
                />
            }
            <Button className={`share-button share-web-api ${link ? '' : 'disabled'}`} onMouseDown={handleButtonClick}
                    $customStyles={customStyles} >
                <div className="">
                    {selectedIcon ? <FontAwesomeIcon icon={selectedIcon} color="black"/> : icon &&
                        <CustomIcon name={icon}/>}
                </div>
            </Button>
        </>
    );
};

export default ShareButton;
