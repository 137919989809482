import {
    faFacebook, faInstagram, faLinkedin, faSpotify,
    faTiktok,
    faTwitch,
    faXTwitter,
    faYoutube,
    IconDefinition
} from "@fortawesome/free-brands-svg-icons";
import {faBan, faGlobe, faLock, faStore} from "@fortawesome/free-solid-svg-icons";

const getIcon = (name: string): IconDefinition | null => {
    switch (name.toLowerCase()) {
        case 'twitch':
            return faTwitch;
        case 'tiktok':
            return faTiktok;
        case 'facebook':
            return faFacebook;
        case 'twitter':
            return faXTwitter;
        case 'x':
            return faXTwitter;
        case 'youtube':
            return faYoutube;
        case 'instagram':
            return faInstagram;
        case 'linkedin':
            return faLinkedin;
        case 'spotify':
            return faSpotify;
        case 'store':
            return faStore;
        case 'website':
            return faGlobe;
        case 'ban':
            return faBan;
        case 'lock':
            return faLock;
        default:
            return null;
    }
};

export default getIcon;