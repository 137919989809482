import React from 'react';

interface Icons {
    [key: string]: JSX.Element | undefined;
}

export const icons: Icons = {
    onlyfans: (
        <svg id="onlyfans" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196.71 131.14">
            <path d="m196.71,0h-32.93C115.83,0,105.46,4.35,85.24,68.62l-19.67,62.52c26.14.01,49.78-15.54,60.13-39.54,26.09-1.9,44.19-17.51,49.9-42.42,0,0-19.67,4.79-36.33,0C172.22,42.79,191.2,24.26,196.71,0Z"/>
            <path d="m65.63,85.24h-.06c-12.15,0-21.73-11.03-19.28-23.62,1.49-7.7,7.71-13.88,15.41-15.36,8.08-1.54,15.48,1.88,19.75,7.73,7.04-20.53,13.3-33.98,20.85-42.75C87.91,1.49,69.44-2.69,49.98,1.8,26.33,7.25,7.51,25.89,1.91,49.51c-9.54,40.21,17.96,76.28,55.25,81.07l14.57-46.33c-1.92.63-3.97.98-6.1.99Z"/>
        </svg>
    ),
    famemma: (
        <svg id="famemma" data-name="Warstwa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 566.93 232.16">
            <polygon points="71.32 60.34 138.75 60.34 149.38 28.08 61.08 28.08 43.92 28.08 33.26 60.34 16.81 111.51 16.79 111.51 0 162.55 38.05 162.55 52.47 118.71 100.77 118.71 111.34 86.51 63.14 86.51 71.32 60.34"/>
            <polygon points="556.45 60.34 566.93 28.18 460.92 28.18 417.14 162.56 528.95 162.56 539.44 130.36 465.78 130.36 471.88 111.61 520.73 111.59 531.22 79.43 482.38 79.43 488.59 60.34 556.45 60.34"/>
            <path d="m254.66,31.73c-4.37-1.91-11.66-3.55-19.98-3.55l-10.48,32.16h19.01l-70.86,51.59,10.59-32.5h-19.73L253.27,0l-53.5,27.86h-.49c-24.3,1.55-39.77,6.2-45.73,24.52-2.3,7.16-4.43,13.51-4.43,13.51l-31.5,96.67h38.24l3.59-11,45.8-33.04-14.35,44.04h38.24l33.3-102.22c1.38-4.17,7.18-21.13-7.78-28.61Z"/>
            <path d="m400.03,28.18c-6.24,0-10.27,4.12-10.27,4.12l-44.53,47.13-9.99-42.27c-.64-2.59-1.09-4.51-2.91-6.36-1.67-1.39-3.24-2.62-8.63-2.62h-27.72l-43.78,134.38h38.23l25.17-77.27,9.98,54.43,55.68-54.87-25.31,77.7h38l43.8-134.37h-37.72Z"/>
            <path d="m379.52,190.25c-.98.66-2.08,1.61-2.68,2.12l-14.13,14.32-4.17-14.42c-.2-.82-.4-1.36-.98-1.95-.58-.59-1.5-.9-2.53-.91h-7.83l-13.92,42.75h12l8.08-24.71,3.39,17.5,17.46-17.54-8.06,24.75h12.01l13.93-42.75h-9.43c-1.38,0-2.45.39-3.14.84Z"/>
            <path d="m448.35,190.25c-.98.66-2.08,1.61-2.68,2.12l-14.19,14.32-4.11-14.42c-.2-.82-.4-1.36-.98-1.95-.58-.59-1.5-.9-2.53-.91h-7.99l-13.92,42.75h12l8.06-24.71,3.53,17.5,17.44-17.54-8.07,24.75h12.01l13.95-42.75h-9.38c-1.38,0-2.45.39-3.14.84Z"/>
            <path d="m508.24,189.41h-11.4c-8.17-.03-12.42,1.62-14.57,7.9,0,0-1.61,4.97-2.44,7.41l-8.96,27.44h12.01l3.66-11.24h11.36s-3.64,11.24-3.64,11.24h12l10.05-30.82c1.69-5.82-.83-11.95-8.07-11.93Zm-4.41,13.38h0l-2.43,7.44h-11.37l2.27-6.96c.64-1.68.76-3.05,3.96-3.05l4.42-.03s1.02.01,1.03.01c.4.06,2.89,0,2.12,2.59Z"/>
        </svg>
    )
};
