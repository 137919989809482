import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShareNodes} from "@fortawesome/free-solid-svg-icons";

interface ShareWebButtonProps {
    title: string;
    url: string;
    text?: string;
}

const ShareApiWebButton: React.FC<ShareWebButtonProps> = ({ title, url, text }) => {
    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({ title, url, text });
            } catch (error) {
                console.error('Failed to share.', error);
            }
        } else {
            console.error('Web Share API is not available.');
        }
    };

    return (
        <button className="share-button share-web-api" onMouseDown={handleShare}><FontAwesomeIcon icon={faShareNodes}/></button>
    );
};

export default ShareApiWebButton;
