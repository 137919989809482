import React from 'react';
import styled from 'styled-components';
import {InfluencerData} from "../abstract/InfluencerData";
import ShareButton from "./ShareButton";
import {getResourceUrl} from "../utils/urlUtils";
import {Col, Row} from 'react-bootstrap';
import InfluencerPartProfile from "./InfluencerPartProfile";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const ProfileContainer = styled.div`
    text-align: center;
    margin: 20px;
`;

const Logo = styled.img`
    width: auto;
    max-height: 120px;
    height: 100%;
    margin-bottom: 10px;
`;

interface Props {
    influencer: InfluencerData;
}

const responsive = {
    superLargeDesktop: {
        breakpoint: {max: 4000, min: 3000},
        items: 2
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 2
    },
    tablet: {
        breakpoint: {max: 1024, min: 610},
        items: 2
    },
    mobile: {
        breakpoint: {max: 610, min: 0},
        items: 1
    }
};

const InfluencerProfile: React.FC<Props> = ({influencer}) => {
    return (
        <ProfileContainer>
            <div style={{width: "100%"}}/>
            <Row className={"justify-content-center"}>
                {influencer.src && <Logo src={getResourceUrl(influencer.src)} alt={influencer.name}/>}
            </Row>
            <h2 className="text-white-shadow">{influencer.name}</h2>
            <p className="text-white-shadow">{influencer.description}</p>
            <Row className={"mb-4 px-2"}>
                {influencer.socialMediaLinks?.map((link, index) => (

                    <Col key={link.id + index}
                        // @ts-ignore
                         className={index === 0 && influencer.socialMediaLinks.length % 2 != 0 ? "col-12" : "col-12 col-md-6"}>
                        <ShareButton key={'share' + link.id + index} title={link.title} link={link.link}
                                     restricted={link.restricted} icon={link.icon}/>
                    </Col>
                ))}
            </Row>
            <div style={{textAlign: "center"}}>
                <h2 className="text-white-shadow fw-bold my-4">TEAM</h2>
            </div>
            <Row>
                <div>
                    <Carousel responsive={responsive} infinite={true} autoPlay={true} itemClass={"px-2"}>
                        {influencer.influencers?.map((influencer, index) => (

                            <Col className={"col-12"} key={index} style={{backdropFilter: 'blur(8px)', borderRadius: '1.25rem'}} >
                                <InfluencerPartProfile key={influencer.name + index} influencer={influencer} />
                            </Col>

                        ))}
                    </Carousel>
                </div>
            </Row>
        </ProfileContainer>
    );
};

export default InfluencerProfile;
