import React from "react";

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>
                    TeenZ Sp. z o.o. | Powered by <a href="https://www.headstartagency.eu/" target="_blank" rel="noopener noreferrer" > <img
                    src="/assets/hsa_logo.svg" alt="Head Start Agency Logo" height="25px" width="25px" className="footer-logo"/> HeadStart
                    Group</a> © {new Date().getFullYear()}. All Rights Reserved.
                </p>
            </div>
        </footer>
    );
}

export default Footer;
